
export default {
  name: 'BaseUserMenuLoginAs',
  data() {
    return {
      accounts: [],
    }
  },
  async fetch() {
    if (this.$auth.loggedIn && this.isAdmin) {
      const moderators = await this.$axios.$get('moderators')
      this.accounts = moderators
    }
  },
  computed: {
    isAdmin() {
      return !!this.$auth?.user?.roles?.some((role) =>
        ['ADMIN', 'MODERATOR'].includes(role)
      )
    },
  },
  methods: {
    async loginAs(username) {
      const auth = await this.$axios.$post('/auth/loginAs', {
        username,
      })
      await this.$auth.setUserToken(auth.accessToken)
      await this.$auth.fetchUser()
      window.location.reload()
    },
  },
}
