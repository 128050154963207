import { render, staticRenderFns } from "./Confirm.vue?vue&type=template&id=1468ea4d&"
import script from "./Confirm.vue?vue&type=script&lang=js&"
export * from "./Confirm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseModal: require('/home/webuser/www/megasreda/frontend-v2/releases/20240313112438/components/Base/Modal/Modal.vue').default})
