
export default {
  name: 'BaseUserMenu',
  props: {
    included: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      scrollTop: 0,
      isOpenModale: false,
      activeModule: null,
      client: {
        status: false,
        author: null,
      },
    }
  },
  computed: {
    isAdmin() {
      return !!this.$auth?.user?.roles?.some((role) =>
        ['ADMIN', 'MODERATOR'].includes(role)
      )
    },
  },
  watch: {
    '$route.path'() {
      this.$usermenu.close()
      this.isOpenModale = false
      this.activeModule = null
    },
  },
  created() {
    if (process?.client) {
      this.$eventBus.$on('userMenuToggle', () => {
        this.isOpenModale = false
        this.activeModule = null
      })

      this.$eventBus.$on('isClientPage', (data) => {
        if (data) {
          this.client = data
        }
      })
    }
  },
  beforeDestroy() {
    document.body.classList.remove('not-overflow')
    this.$eventBus.$off('isClientPage')
  },
  methods: {
    async logout() {
      this.close()
      const isLogout = await this.$refs.confirmLogout.show({
        title: 'Выход',
        message: 'Вы действительно хотите выйти?',
        okButton: 'Выйти',
      })
      if (isLogout) {
        await this.$auth.logout()
      }
    },
    openLoginAs() {
      if (this.isOpenModale && this.activeModule === 'loginAs') {
        this.close()
      } else {
        const { body, documentElement } = document
        this.scrollTop = documentElement.scrollTop
        body.style.top = `-${this.scrollTop}px`
        document.body.classList.add('not-overflow')
        this.isOpenModale = true
        this.activeModule = 'loginAs'
      }
    },
    openBroadcasts() {
      if (this.isOpenModale && this.activeModule === 'events') {
        this.close()
      } else {
        const { body, documentElement } = document
        this.scrollTop = documentElement.scrollTop
        body.style.top = `-${this.scrollTop}px`
        document.body.classList.add('not-overflow')
        this.isOpenModale = true
        this.activeModule = 'broadcasts'
      }
    },
    // openEvents() {
    //   if (this.isOpenModale && this.activeModule === 'events') {
    //     this.close()
    //   } else {
    //     const { body, documentElement } = document
    //     this.scrollTop = documentElement.scrollTop
    //     body.style.top = `-${this.scrollTop}px`
    //     document.body.classList.add('not-overflow')
    //     this.isOpenModale = true
    //     this.activeModule = 'events'
    //   }
    // },
    // openOffers() {
    //   if (this.isOpenModale && this.activeModule === 'offers') {
    //     this.close()
    //   } else {
    //     const { body, documentElement } = document
    //     this.scrollTop = documentElement.scrollTop
    //     body.style.top = `-${this.scrollTop}px`
    //     document.body.classList.add('not-overflow')
    //     this.isOpenModale = true
    //     this.activeModule = 'offers'
    //   }
    // },
    close() {
      const { body, documentElement } = document
      document.body.classList.remove('not-overflow')
      documentElement.scrollTop = this.scrollTop
      body.style.removeProperty('top')
      this.isOpenModale = false
      this.activeModule = null
    },
    clickInOverlay() {
      this.close()
    },
    handlerMousemove(event) {
      if (
        this.$auth.loggedIn &&
        !this.$auth.user.verified &&
        !this.$cookies.get('goOutClose') &&
        event.clientY < 10
      ) {
        this.isOpenModale = true
        this.activeModule = 'broadcasts'
        this.$cookies.set('goOutClose', true, {
          path: '/',
          maxAge: 60 * 60 * 24, // 24 часа
        })
      } else if (
        !this.$auth.loggedIn &&
        !this.$cookies.get('goOutClose') &&
        event.clientY < 10
      ) {
        this.isOpenModale = true
        this.activeModule = 'broadcasts'
        this.$cookies.set('goOutClose', true, {
          path: '/',
          maxAge: 60 * 3, // 3 минуты
        })
      }
    },
  },
}
