import { render, staticRenderFns } from "./index.vue?vue&type=template&id=48bfa7b4&scoped=true&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "./index.vue?vue&type=style&index=0&id=48bfa7b4&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48bfa7b4",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseUserMenuLoginAs: require('/home/webuser/www/megasreda/frontend-v2/releases/20240313112438/components/Base/User/Menu/LoginAs.vue').default,BaseUserMenuBroadcasts: require('/home/webuser/www/megasreda/frontend-v2/releases/20240313112438/components/Base/User/Menu/Broadcasts.vue').default,CoreIcon: require('/home/webuser/www/megasreda/frontend-v2/releases/20240313112438/components/Core/Icon/Icon.vue').default,BaseModalConfirm: require('/home/webuser/www/megasreda/frontend-v2/releases/20240313112438/components/Base/Modal/Confirm/Confirm.vue').default})
