
const LIMIT = 5
export default {
  name: 'BaseUserMenuBroadcasts',
  scrollToTop: true,
  data() {
    return {
      products: [],
      productsCount: 0,
      productsOffset: 0,
    }
  },
  async fetch() {
    await this.fetchBroadcasts()
  },

  watch: {
    activeFetch() {
      this.products = []
      this.productsOffset = 0
      this.$fetch()
    },
  },

  methods: {
    async fetchBroadcasts() {
      const [broadcasts, broadcastsCount] = await Promise.all([
        this.$axios.$get('advertisements/recommended/broadcasts', {
          params: {
            limit: LIMIT,
            offset: this.productsOffset,
          },
        }),
        this.$axios.$get('advertisements/recommended/broadcasts/count'),
      ])

      this.productsCount = broadcastsCount
      this.products = this.products?.length
        ? this.products.concat(broadcasts)
        : broadcasts
    },
    loadMore() {
      this.productsOffset = this.productsOffset + LIMIT
      this.fetchBroadcasts()
    },
  },
}
