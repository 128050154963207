
export default {
  name: 'BaseModal',
  props: {
    size: {
      type: String,
      default: 'small',
    },
    closeInClickOutside: {
      type: Boolean,
      default: false,
    },
    full: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isVisible: false,
    }
  },

  methods: {
    open() {
      this.isVisible = true
    },

    close() {
      this.isVisible = false
    },
  },
}
